import api from './api'
export default {
    addMaintenance(data){
        return api.execute(`POST`,`/mwindow`,data)
    },
    getAll(paginationQuery){
        return api.execute(`GET`,`/mwindow`+paginationQuery)
    },
    editMaintenance(data){
        return api.execute(`PUT`,`/mwindow`,data)
    },
    deleteMaintenance(data){
        return api.execute(`DELETE`,`/mwindow`,data)
    }
}