<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <form action="" @submit.prevent="onSubmit()">
                    <px-card>
                        <b-row>
                            <b-col>
                                <h6 class="text-primary">Maintenance Window Information</h6>
                            </b-col>
                        </b-row><hr>
                        <b-row>
                            <b-col md="3" class="font-weight-bold">
                                Maint. Window Type
                                <span class="font-danger">*</span>
                            </b-col>
                            <b-col md="8">
                                <div class="form-group">
                                <b-form-select name="type" size="sm" v-model="input.type" :options="maintenance_type_opt" :state="isMwindowSelected ? !invalid_mwindow_message : null" @change="validate_type" 
                                required>
                                </b-form-select>
                                <b-form-invalid-feedback v-if="isMwindowSelected">{{ invalid_mwindow_message }}</b-form-invalid-feedback>
                                </div>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row class="mb-2">
                            <b-col md="3" class="font-weight-bold">
                                Friendly Name 
                                <span class="font-danger">*</span>
                            </b-col>
                            <b-col md="8">
                                <b-form-input size="sm" type="text" name="name" v-model="input.name" :state="isNameEntered ? !invalid_fname_message : null"  @input="validateName"  maxlength="50" placeholder="Name" required></b-form-input>
                                <b-form-invalid-feedback v-if="isNameEntered">{{ invalid_fname_message }}</b-form-invalid-feedback>
                            </b-col>
                        </b-row><hr>
                        <b-row class="mb-2">
                            <b-col md="3" class="font-weight-bold">
                                TimeZone
                            </b-col>
                            <b-col md="8">
                                <b-form-select name="type" size="sm" v-model="input.timezone" :options="timezone_list" search="true"></b-form-select>
                            </b-col>
                        </b-row><hr>
                        <div v-if="input.type">
                        <b-row class="">
                            <b-col md="3" class="font-weight-bold">
                                Maint. Start Time <span class="font-danger">*</span>
                            </b-col>
                            <b-col md="8">
                                <vue-timepicker 
                                v-model="input.startTime" 
                                format="HH:mm" 
                                @input="validateStartTime"
                                :class="{ 'invalid-timepicker': isTimeInvalid, 'valid-timepicker': isTimeValid }"
                                >
                                </vue-timepicker>
                                <div v-if="isTimeInvalid" class="invalid-feedback d-block">
                                    {{ invalid_startTime_message }}
                                </div>
                            </b-col>
                        </b-row><hr>
                        </div>
                        <b-row class="">
                            <b-col md="3" class="font-weight-bold">
                                Maint. Duration <span class="font-danger">*</span>(minutes.)
                            </b-col>
                            <b-col md="8">
                                <b-form-input size="sm" type="number" name="name" v-model="input.duration" :state="isDurationEntered ? !invalid_duration_message : null"  @input="validateDuration"   placeholder="Duration" min="1" required></b-form-input>
                                <b-form-invalid-feedback v-if="isDurationEntered">{{ invalid_duration_message }}</b-form-invalid-feedback>
                            </b-col>
                        </b-row><hr>
                        <b-row v-if="input.type === 1">
                            <b-col md="3" class="font-weight-bold">
                                Maint. Start Date <span class="font-danger">*</span>
                            </b-col>
                            <b-col md="8">
                                <b-form-datepicker v-model="input.datetime" :state="isDateSelected ? !invalid_date_message : null"  @input="validateDate" :date-disabled-fn="dateDisabled" required></b-form-datepicker>
                                <b-form-invalid-feedback v-if="isDateSelected">{{ invalid_date_message }}</b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                        <b-row v-if="input.type === 3 || input.type === 4" class="">
                            <b-col md="3" class="font-weight-bold">
                                Value <span class="font-danger">*</span>
                            </b-col>
                            <b-col md="8" v-if="input.type === 3">
                                <multiselect  
                                size="sm" 
                                v-model="input.value" tag-placeholder="Add this as new tag" placeholder="Select Week Days" 
                                label="text" 
                                track-by="value" 
                                :options="weekly_opt" 
                                :multiple="true" :close-on-select="false"
                                @input="validateSelectedWeek" 
                                :class="{ 'invalid-select': isValueInvalid, 'valid-select': isValueValid }"
                                >
                                </multiselect>
                                <div v-if="isValueInvalid" class="invalid-feedback d-block">
                                    {{ invalid_weekDay_message }}
                                </div>
                            </b-col>
                            <b-col v-if="input.type === 4">
                                <multiselect 
                                size="sm" 
                                v-model="input.value" tag-placeholder="Add this as new tag" placeholder="Select Days Of Month" 
                                label="text" track-by="value" :options="monthly_opt" 
                                :multiple="true" :close-on-select="false"
                                @input="validateSelectedMonth"
                                :class="{ 'invalid-select': isValueInvalid, 'valid-select': isValueValid }"
                                >
                                </multiselect>

                                <div v-if="isValueInvalid" class="invalid-feedback d-block">
                                    {{ invalid_monthDay_message }}
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3 text-center">
                            <b-col>
                                <b-button type="submit" variant="primary" :disabled="disable_submit">{{edit_maint?'Edit Maintenance':'Add New Maintenance'}}</b-button>
                            </b-col>
                        </b-row>
                    </px-card>
                </form>
            </div>
        </div>
    </div>
</template>


<script>
import MaintenanceController from "../../../services/maintenanceController";
import Multiselect from 'vue-multiselect';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
export default{
    name : "AddMaintenance",
    components:{Multiselect,VueTimepicker},
    props:{add_maint_modal:Boolean,edit_maint:Boolean,maint_data:Object},
    data(){
        return {
            timezone_list: Intl.supportedValuesOf('timeZone'),
            disable_submit:false,
            input:{type:null,name:'',startTime:'',duration:1,value:[],datetime:'',timezone:Intl.DateTimeFormat().resolvedOptions().timeZone},
            maintenance_type_opt:[
              { value: null, text:'Please Select' },
              { value: 1, text:'Once' },
              { value: 2, text:'Daily' },
              { value: 3, text:'Weekly' },
              { value: 4, text:'Monthly' },
            ],
            weekly_opt:[
                { value: 1, text:'Monday' },
                { value: 2, text:'Tuesday' },
                { value: 3, text:'Wednesday' },
                { value: 4, text:'Thursday' },
                { value: 5, text:'Friday' },
                { value: 6, text:'Saturday' },
                { value: 0, text:'Sunday' },
            ],
            monthly_opt:[
                { value: 1, text:'1st' },
                { value: 2, text:'2nd' },
                { value: 3, text:'3rd' },
                { value: 4, text:'4th' },
                { value: 5, text:'5th' },
                { value: 6, text:'6th' },
                { value: 7, text:'7th' },
                { value: 8, text:'8th' },
                { value: 9, text:'9th' },
                { value: 10, text:'10th' },
                { value: 11, text:'11th' },
                { value: 12, text:'12th' },
                { value: 13, text:'13th' },
                { value: 14, text:'14th' },
                { value: 15, text:'15th' },
                { value: 16, text:'16th' },
                { value: 17, text:'17th' },
                { value: 18, text:'18th' },
                { value: 19, text:'19th' },
                { value: 20, text:'20th' },
                { value: 21, text:'21st' },
                { value: 22, text:'22nd' },
                { value: 23, text:'23rd' },
                { value: 24, text:'24th' },
                { value: 25, text:'25th' },
                { value: 26, text:'26th' },
                { value: 27, text:'27th' },
                { value: 28, text:'28th' },
                { value: 29, text:'29th' },
                { value: 30, text:'30th' },
                { value: 31, text:'31st' },
            ],
            payload:{type:null,name:'',startTime:'',duration:1,value:[]},
            invalid_fname_message: 'The name field is required',
            invalid_mwindow_message: 'Please select maintenance window type',
            isMwindowSelected: false,
            isNameEntered: false,
            isDurationEntered: false,
            invalid_duration_message: 'The duration field is required',
            isDateSelected: false,
            invalid_date_message: 'Please select any date',
            invalid_weekDay_message: 'Please select atleast one week day',
            invalid_monthDay_message: 'Please select atleast one month day',
            invalid_startTime_message: 'Please select valid start time',
            isValueValid: false,
            isValueInvalid: false,
            isTimeValid: false,
            isTimeInvalid: false,
        }
    },
    computed:{
        validate_name(){
            if(this.input.name == ''){return false;}
            else{return true;}
        },
    },
    mounted(){
        this.input.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.input.startTime = this.getCurrentDateTime()
        if(this.edit_maint){
                this.isMwindowSelected = true;
                this.invalid_mwindow_message = '';
                this.isNameEntered = true;
                this.invalid_fname_message = '';
                this.isDurationEntered = true;
                this.invalid_duration_message = '';
                this.isDateSelected = true;
                this.invalid_date_message = '';

                this.input.type = this.maint_data.type
                this.input.name = this.maint_data.name
                this.input.startTime = this.maint_data.startTime
                this.input.duration = this.maint_data.duration
                this.input._id = this.maint_data._id
                if(this.maint_data.timezone){
                    this.input.timezone = this.maint_data.timezone
                }
            if(this.input.type === 1){
                this.input.datetime = this.changeDateFormate(this.maint_data.value[0])
            }
            else if(this.input.type === 3){
                this.input.value = this.weekly_opt.filter((element) => {
                    return this.maint_data.value.includes(element.value)
                }) 
            }
            else if(this.input.type === 4){
                this.input.value = this.monthly_opt.filter((element) => {
                    return this.maint_data.value.includes(element.value)
                }) 
            }
        }
    },
    methods:{
        validateName(name){
            this.isNameEntered = true;
            name = name ? name : this.input.name

            let regexp = /^(?!\s*$)[a-zA-Z0-9 ._-]+$/;
            if (regexp.test(name)) {
                this.invalid_fname_message = false;
                return true;
            } 
            else{ 
                this.invalid_fname_message = 'Please enter valid friendly name';
                return false; 
            }
        },
        dateDisabled(ymd, date) {
            let today = new Date();
            today.setHours(0, 0, 0, 0); // Remove time part to compare only dates
            return date < today; // Disable past dates
        },
        changeDateFormate(utcDateTime){
            // Create a Date object from the UTC date-time string
            let dateTime = new Date(utcDateTime);
            // Extract day, month, year, hours, and minutes in local time
            let day = String(dateTime.getDate()).padStart(2, "0");
            let month = String(dateTime.getMonth() + 1).padStart(2, "0");
            let year = dateTime.getFullYear();
     
            // Format the components into the desired format
            let formattedDateTime = `${year}-${month}-${day}`;
            return formattedDateTime
        },
        getCurrentDateTime() {
            const now = new Date();
            const timeString = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            return timeString;
        },
       async onSubmit(){
        if(this.input.type === 1 ){
            if(this.input.datetime == null || this.input.datetime == ''){
                this.$toasted.show("Please select any date", {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                return
            }
        }
        if(this.input.type === 3 || this.input.type === 4){
            if(this.input.value.length === 0){
                this.$toasted.show("Please select atleast one value", {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                return
            }
        }
        this.disable_submit = true
        var userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        switch(this.input.type){
            case 1:
                this.payload = {"type": this.input.type,"name": this.input.name,"startTime": this.input.startTime,"duration": parseInt(this.input.duration),"timezone": this.input.timezone}
                // let t = new Date(this.input.datetime).toISOString()
                this.payload.value = []
                this.payload.value.push(this.input.datetime)
                break;
            case 2:
                this.input.startTime = this.input.startTime
                this.payload = {"type": this.input.type,"name": this.input.name,"startTime": this.input.startTime,"duration": parseInt(this.input.duration),"value": [],"timezone": this.input.timezone}
                break;
            case 3:
            case 4:
                this.input.startTime = this.input.startTime
                this.payload = {"type": this.input.type,"name": this.input.name,"startTime": this.input.startTime,"duration": parseInt(this.input.duration),"value":[],"timezone": this.input.timezone}
                this.input.value.forEach((element) => {
                    this.payload.value.push(element.value)
                });
                break;
        }
        var response
                if(this.edit_maint){
                     var p = { "mwid": this.input._id, "changePayload":this.payload }
                     response= await MaintenanceController.editMaintenance(p)
                }
                else{
                    response= await MaintenanceController.addMaintenance(this.payload)
                    
                }
                if(response.data){
                        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
                        this.$emit('maintmodal')  
                } else{
                    this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                }
        this.disable_submit = false
        },
        validate_type(){
            this.isMwindowSelected = true
            if(!this.input.type){
                this.invalid_mwindow_message = 'Please select maintenance window type'
            }
            else{
                this.invalid_mwindow_message = ''
            }
        },
        validateDuration(){
            this.isDurationEntered = true;
            if(this.input.duration < 1){
                this.invalid_duration_message = 'Please enter valid duration'
            }
            else{
                this.invalid_duration_message = ''
            }
        },
        validateDate(){
            this.isDateSelected = true;
            if(!this.input.datetime){
                this.invalid_date_message = 'Please select any date'
            }
            else{
                this.invalid_date_message = ''
            }
        },
        validateSelectedWeek(){
            if(this.input.type === 3) {
                if(!this.input.value || this.input.value.length === 0){
                    this.isValueInvalid = true
                    this.isValueValid = false
                    this.invalid_value_message = 'Please select atleast one week day'
                }
                else{
                    this.isValueInvalid = false
                    this.isValueValid = true
                    this.invalid_value_message = ''
                }
            }
        },
        validateSelectedMonth(){
            if(this.input.type === 4) {
                if(!this.input.value || this.input.value.length === 0){
                    this.isValueInvalid = true
                    this.isValueValid = false
                    this.invalid_value_message = 'Please select atleast one month day'
                }
                else{
                    this.isValueInvalid = false
                    this.isValueValid = true
                    this.invalid_value_message = ''
                }
            }
        },
        validateStartTime(){
            if(!this.input.startTime || this.input.startTime == null){
                this.isTimeInvalid = true
                this.isTimeValid = false
                this.invalid_startTime_message = 'Please select valid start time'
            }
            else{
                this.isTimeInvalid = false
                this.isTimeValid = true
                this.invalid_startTime_message = ''
            }
        }
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>